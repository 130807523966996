<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="950"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
        >
          Clock All Out
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Clock out all employees at a certain time today?
        </v-card-title>
        <v-card-text>Employees will only be clocked-out if they are currently on the clock (indicated by <span
            class="font-weight-black green--text">green</span> color)
          <v-card
              class="d-flex justify-space-around mb-6 pt-6"
              flat
              tile
          >
            <v-data-table
                :headers="headers"
                :items="employees"
                dense
                disable-pagination
                hide-default-footer
            >
              <template v-slot:body="{ items }">
                <tbody>
                <tr :key="empl.id" v-for="empl in items">
                  <td :class="{'font-weight-black green--text': empl.on_clock}"
                      class="text-left">
                    {{ empl.last }}, {{ empl.first }}
                  </td>
                  <td :class="{'font-weight-black green--text': empl.on_clock}"
                      class="text-center">
                    {{ prettyDT(empl.last_clockin) }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-data-table>

            <v-time-picker
                ampm-in-title format="ampm"
                v-if="dialog"
                v-model="pickerOut"
                width="310"
                elevation="1">
            </v-time-picker>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-alert :value="(errorMessage!=='')" outlined type="error">
            {{ errorMessage }}
          </v-alert>
          <VSpacer/>
          <v-btn
              color="primary"
              text
              @click="done"
          >
            Cancel
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="save"
          >
            Clock Out
          </v-btn>
        </v-card-actions>
      </v-card>
      <busy :message="busyMessage"></busy>
    </v-dialog>
  </v-row>
</template>

<script>
import {formatISO, prettyDT} from "@/components/Admin/utils";
import {parse} from "date-fns";
import axios from "axios";
import {eventBus} from "@/main";
import {EVT_FINISHED_CLOCK_ALL_OUT} from "@/components/constants";
import Busy from "@/components/Admin/Busy.vue";

export default {
  name: "ClockAllOut",
  components: {Busy},
  beforeDestroy() {
    this.busyMessage = ''
    this.errorMessage = ''
    console.log('BeforeDestroy:', this.$options.name)
  },

  computed: {
    employees() {
      return this.$store.getters.activeEmployees // only active
    },
  },

  methods: {
    prettyDT,

    getPicker() { // parse time from picker, combine with today's date
      if (this.pickerOut === '') return '' // no clock out time entered

      // convert pickerOut to proper datetime using today with picker time
      let time_out = parse(this.pickerOut, 'HH:mm', new Date())
      time_out.setSeconds(0)
      time_out.setMilliseconds(0)
      return time_out
    },

    save() {
      console.log('SAVE')

      let picker = this.getPicker()
      if (picker === '') {
        this.showAlert('Clock-out must be a valid time.')
        return
      }

      this.busyMessage = 'Saving...'

      let promises = []
      for (const empl of this.employees) {
        if (empl.on_clock) promises.push(this.sendClockout(empl.id, picker))
      }

      Promise.all(promises)
          .then(() => {
            console.log("Promises resolved")
            this.done()
          })
          .catch((error) => {
            console.log("Promises failed")
            this.busyMessage = ''
            this.showAlert('Error unable to save clock-out records ' + error.toString())
            console.error(error.toString());
          })
    },
    done() {
      console.log("DONE ClockAllOut")
      this.busyMessage = ''
      eventBus.$emit(EVT_FINISHED_CLOCK_ALL_OUT)
      this.dialog = false
    },

    async sendClockout(empl_id, clockout) {
      let rec = {
        'id': 0, // new clock out (not an update)
        'empl_id': empl_id,
        'time': formatISO(clockout),
        'in': false, // clock out
      }

      console.log("Axios post")
      return axios.post('/clockin/add', rec)
    },

    showAlert(msg) {
      this.errorMessage = msg
      setTimeout(() => (this.errorMessage = ''), 5000)
    },
  },

  data() {
    return {
      dialog: false,
      pickerOut: '09:00',
      busyMessage: '',
      errorMessage: '',
      headers: [
        {text: 'Name', align: 'left', value: 'last'},
        {text: 'Last seen', align: 'center', value: 'last_clockin'},
      ]
    }
  }
}
</script>

<style scoped>
</style>