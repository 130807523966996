<template>
  <v-dialog
      persistent
      v-model="dialog"
      width="640px"
  >

    <v-card height="660px">
      <v-card-title class="grey lighten-2">
        <div>
          <h3 class="headline mb-0">{{ item.action }} worked period for {{ item.first }} {{ item.last }}</h3>
          <div>For date {{ prettyDay(item.date) }}</div>
        </div>
      </v-card-title>

      <v-container>
        <div class="mx-9">
          <v-row fill-height self-justify="space-around">
            <v-col class="text-center" cols="6" self-justify="space-around">
              <v-row class="text-center">Clocked In</v-row>
              <v-row>
                <v-time-picker ampm-in-title format="ampm" v-if="dialog" v-model="pickerIn"
                               width="280" elevation="1"></v-time-picker>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>Clocked Out</v-row>
              <v-row>
                <v-time-picker ampm-in-title format="ampm" v-if="dialog" v-model="pickerOut"
                               width="280" elevation="1"></v-time-picker>
              </v-row>
            </v-col>
          </v-row>
          <v-row align-center fill-height justify-end>
            <v-col v-if="dialog">Hours: {{ calculatedHours }}</v-col>
          </v-row>
        </div>
      </v-container>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="done" color="primary" text>
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-alert :value="(errorMessage!=='')" outlined type="error">
          {{ errorMessage }}
        </v-alert>
        <v-spacer></v-spacer>
        <v-btn @click="save" color="primary" text>
          Save
        </v-btn>
      </v-card-actions>

    </v-card>

    <busy :message="busyMessage"></busy>

  </v-dialog>
</template>

<script>
import axios from 'axios'
import {eventBus} from '@/main'
import {parse, differenceInSeconds, isAfter, parseISO} from 'date-fns'
import {financialI, prettyT24, prettyDay, isZeroDate, convertDT} from '../utils'
import {EVT_EDIT_HOURS_ITEM, EVT_FINISHED_EDIT_HOURS_ITEM} from '../../constants'
import Busy from '../Busy'
import {formatISO} from '../utils'

export default {
  name: 'EditEmplHoursItem',
  components: {Busy},

  created() {
    console.log('Created:', this.$options.name)
    eventBus.$on(EVT_EDIT_HOURS_ITEM, (item) => {
      console.log('EDIT HOURS ITEM', item)
      console.log('CLOCKINOUT:', item.clockin, item.clockout)
      this.item = item
      this.date = parse(this.item.date, 'yyyy-MM-dd', new Date())
      // TODO probably most things here should be converted to use these "x" values
      //  instead of raw strings from backend
      this.clockin = convertDT(item.clockin)
      this.clockout = convertDT(item.clockout)
      console.log('X', this.clockin, this.clockout)
      this.pickerIn = prettyT24(item.clockin)
      this.pickerOut = prettyT24(item.clockout)
      this.dialog = true
    })
  },
  beforeDestroy() {
    this.busyMessage = ''
    this.errorMessage = ''
  },

  computed: {
    calculatedHours() {
      let [time_in, time_out] = this.getPickers()

      // make sure both are valid
      if (time_in === '' || time_out === '') return ''

      // validate that clockout is not before clockin
      if (isAfter(time_in, time_out)) {
        this.showAlert('Clock-in time must be before clock-out time.')
        return '' // show zero hours calculated
      }

      let hours = this.calcHours(time_in, time_out)
      console.log('HOURS:', hours)
      if (isNaN(hours)) hours = ''
      return hours
    }
  },

  methods: {
    financialI,
    prettyDay,
    isZeroDate,
    convertDT,

    getPickers() {
      // parse time from pickers, combine with date of selected item
      let time_in = ''
      let time_out = ''
      let ref = ''

      // convert pickerIn to proper datetime
      if (this.pickerIn !== '') {
        ref = this.clockin
        if (isZeroDate(this.clockin)) ref = this.date
        time_in = parse(this.pickerIn, 'HH:mm', ref)
        time_in.setSeconds(0)
        time_in.setMilliseconds(0)
      }
      // convert pickerOut to proper datetime
      if (this.pickerOut !== '') {
        ref = this.clockout
        if (isZeroDate(this.clockout)) ref = this.date
        time_out = parse(this.pickerOut, 'HH:mm', ref)
        time_out.setSeconds(0)
        time_out.setMilliseconds(0)
      }

      return [time_in, time_out]
    },

    save() {
      console.log('SAVE')
      // if neither picker has valid time, can't save, error
      if (this.pickerIn === '' && this.pickerOut === '') {
        this.showAlert('Clock-in or clock-out must be a valid time.')
        return
      }

      let [time_in, time_out] = this.getPickers()

      // validate that clockout is after clockin, only if both present
      if (time_in !== '' && time_out !== '') {
        if (isAfter(parseISO(time_in), parseISO(time_out))) {
          this.showAlert('Clock out time must not be before clock in time.')
          return
        }
      }

      this.busyMessage = 'Saving...'

      if (time_in !== '') {
        this.sendClockin(this.item.in_id, this.item.empl_id, formatISO(time_in), true)
      }

      if (time_out !== '') {
        this.sendClockin(this.item.out_id, this.item.empl_id, formatISO(time_out), false)
      }
    },

    sendClockin(id, empl_id, clockin, cin) {
      let rec = {
        'id': id,
        'empl_id': empl_id,
        'time': clockin,
        'in': cin
      }
      // TODO there's a bug here that this is called twice and
      // TODO either one can initiate 'done' or clear the busy message
      if (id <= 0) {
        console.log('SAVE NEW', rec)
        axios.post('/clockin/add', rec)
            .then(res => {
              console.log('PUT', res)
              this.busyMessage = ''
              this.done()
            })
            .catch(error => {
              console.log(error.toString())
              this.busyMessage = ''
              this.showAlert('Error unable to save edited work record. ' + error.toString())
            })
      } else {
        console.log('SAVE EDITED', rec)
        axios.patch('/clockin/edit', rec)
            .then(res => {
              console.log('PUT', res)
              this.busyMessage = ''
              this.done()
            })
            .catch(error => {
              console.log(error.toString())
              this.busyMessage = ''
              this.showAlert('Error unable to save edited work record. ' + error.toString())
            })
      }
    },

    showAlert(msg) {
      this.errorMessage = msg
      setTimeout(() => (this.errorMessage = ''), 5000)
    }
    ,
    done() {
      eventBus.$emit(EVT_FINISHED_EDIT_HOURS_ITEM)
      this.dialog = false
    }
    ,
    calcHours(timeIn, timeOut) {
      var hours
      var secs
      secs = differenceInSeconds(timeOut, timeIn)
      hours = this.financialI(Math.round(secs / 36))
      console.log('HOURS:', hours)
      return hours
    }
  },

  data() {
    return {
      dialog: false,
      showLogin: false,
      pickerIn: '',
      pickerOut: '',
      date: '',
      item: {},
      busyMessage: '',
      errorMessage: ''
    }
  }
}
</script>

<style scoped>
</style>
