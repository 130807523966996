<template>
  <div>
    <v-dialog
        max-width="700px"
        persistent
        v-model="dialog"
    >
      <v-card>
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">Edit hours worked for {{ employee.first }} {{ employee.last }}</h3>
            <div>Payroll period {{ item.start }} - {{ item.end }}</div>
          </div>
        </v-card-title>

        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="worked"
              class="elevation-1"
              disable-pagination
              hide-default-footer
          >
            <template v-slot:body="{ items }">
              <tbody>
              <tr :key="item.name" v-for="item in items">

                <td class="text-center font-weight-bold">{{ prettyDay(item.date) }}</td>
                <td
                    :class="getHourColor(item)"
                    class="text-center"
                >
                  {{ prettyT(item.clockin) }}
                </td>
                <td
                    :class="getHourColor(item)"
                    class="text-center"
                >
                  {{ prettyT(item.clockout) }}
                  <span v-if="isDiffDay(item.clockin, item.clockout)">
                    {{ prettyDay(item.clockout) }}
                  </span>
                </td>
                <td class="text-center">{{ financialI(item.hours) }}</td>
                <td class="justify-center layout px-0">
                  <v-icon @click="editItem(item)" class="mr-2" small v-if="!isBlank(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon @click="addItem(item)" class="mr-2" small>
                    mdi-plus
                  </v-icon>
                  <v-icon
                      @click="confirmDelete(item)"
                      class="mr-2"
                      small
                      v-if="!isBlank(item)">
                    mdi-trash-can
                  </v-icon>

                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-center font-weight-bold">
                  {{ financialI(summary.total_hours) }}
                </td>
                <td></td>
              </tr>
              </tbody>

            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="refresh" color="primary" text>
            Refresh
          </v-btn>

          <v-spacer></v-spacer>
          <v-alert :value="(errorMessage!='')" outlined type="error">
            {{ errorMessage }}
          </v-alert>
          <v-spacer></v-spacer>

          <v-btn @click="done" color="primary" text>
            Done
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog max-width="460" v-model="showConfirmDelete">
        <v-card>
          <v-card-title class="headline">Delete this clock-in item?</v-card-title>

          <v-card-text>
            Delete this clock-in record for {{ employee.first }} {{ employee.last }}
            for date {{ prettyDay(itemDelete.date) }},
            clockin {{ prettyT(itemDelete.clockin) }}, clockout {{ prettyT(itemDelete.clockout) }}?
            This action is irreversible.
          </v-card-text>

          <v-card-actions>
            <v-btn @click="showConfirmDelete = false" color="primary" text>Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="deleteItem()" color="primary" text>Delete item</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <busy :message="busyMessage"></busy>

    </v-dialog>

    <EditEmplHoursItem/>
  </div>
</template>

<script>
import axios from 'axios'
import { eventBus } from '../../../main'
import EditEmplHoursItem from './EditHoursItem'
import Busy from '../Busy'
import { getHourColor, isBlank, financialI, prettyT, prettyDay, isDiffDay } from '../utils'
import {
  EVT_EDIT_EMPLOYEE_HOURS,
  EVT_FINISHED_EDIT_EMPLOYEE_HOURS,
  EVT_EDIT_HOURS_ITEM,
  EVT_FINISHED_EDIT_HOURS_ITEM
} from '../../constants'
import { format } from 'date-fns'

export default {
  name: 'EditEmplHours',
  components: { EditEmplHoursItem, Busy },

  created () {
    console.log('Created:', this.$options.name)
    eventBus.$on(EVT_EDIT_EMPLOYEE_HOURS, (item) => {
      console.log("EVT_EDIT_EMPLOYEE_HOURS")
      console.log('EDIT HOURS', item)
      console.log('EDIT HOURS', item.employee)
      this.item = item
      this.employee = item.employee
      this.dialog = true
      this.refresh()
    })
    eventBus.$on(EVT_FINISHED_EDIT_HOURS_ITEM, () => {
      console.log('FINISHED EDIT HOURS ITEM')
      this.refresh()
    })
  },
  beforeDestroy () {
    this.busyMessage = ''
    this.errorMessage = ''
  },

  computed: {},

  methods: {
    getHourColor,
    isBlank,
    financialI,
    prettyT,
    prettyDay,
    isDiffDay,

    day (date) {
      console.log("Date:", date)
      return format(date, "EEE MM-dd")
    },
    onRefreshClick () {
      console.log('REFRESH CLICK')
      this.refresh()
    },
    refresh () {
      console.log('REFRESH employee hours worked:', this.employee.id)
      this.busyMessage = 'Loading...'
      axios.get(`${this.item.payrollURL}/worked/${this.employee.id}`)
          .then(res => {
            console.log("Worked:", res)
            this.summary = res.data
            this.worked = this.summary.worked
            this.busyMessage = ''
          })
          .catch(error => {
            console.log(error.toString())
            this.busyMessage = ''
            this.showAlert('Error unable to refresh hours worked. ' + error.toString())
          })
    },
    showAlert (msg) {
      this.errorMessage = msg
      setTimeout(() => (this.errorMessage = ''), 5000)
    },
    editItem (item) {
      console.log('EDIT ITEM:', item)
      this.activateEditor(item, false) // false indicates edit existing rec
    },
    addItem (item) {
      console.log('ADD ITEM:', item)
      this.activateEditor(item, true) // true indicates add new rec
    },
    confirmDelete (item) {
      this.itemDelete = item
      if (this.isBlank(this.itemDelete)) {
        console.log('Item is blank')
        return
      }
      this.showConfirmDelete = true
    },
    deleteItem () {
      console.log('DELETE ITEM:', this.itemDelete)
      this.showConfirmDelete = false
      if (this.isBlank(this.itemDelete)) {
        console.log('Item is blank')
        return
      }

      this.busyMessage = 'Deleting...'
      // TODO break this out into its own function
      // TODO there's a bug here that this is called twice and
      // TODO either one can clear the busy message or call refresh
      axios.delete(`/clockin/delete/${this.itemDelete.in_id}`)
          .then(res => {
            console.log('DELETE', res)
            this.busyMessage = ''
            this.refresh()
          })
          .catch(error => {
            console.log(error.toString())
            this.busyMessage = ''
            this.showAlert('Error unable to delete work record. ' + error.toString())
            this.refresh()
          })
      axios.delete(`/clockin/delete/${this.itemDelete.out_id}`)
          .then(res => {
            console.log('DELETE', res)
            this.busyMessage = ''
            this.refresh()
          })
          .catch(error => {
            console.log(error.toString())
            this.busyMessage = ''
            this.showAlert('Error unable to delete work record. ' + error.toString())
            this.refresh()
          })
    },
    activateEditor (item, add) {
      var rec
      rec = {
        'last': this.employee.last,
        'first': this.employee.first,
        'empl_id': item.empl_id,
        'clockin': item.clockin,
        'clockout': item.clockout,
        'date': item.date,
        'action': 'Edit',
        'in_id': item.in_id,
        'out_id': item.out_id
      }
      // was add clicked or is the rec a fake (id<0 forces add)
      if (add) {
        rec.action = 'Add'
        rec.clockin = ''
        rec.clockout = ''
        rec.in_id = -1
        rec.out_id = -1
      }
      eventBus.$emit(EVT_EDIT_HOURS_ITEM, rec)
    },
    done () {
      eventBus.$emit(EVT_FINISHED_EDIT_EMPLOYEE_HOURS)
      this.dialog = false
    }
  },

  data () {
    return {
      dialog: false,
      item: {},
      employee: {},
      summary: {},
      worked: [],
      headers: [
        { text: 'Day', align: 'center', value: 'day', sortable: false },
        { text: 'Clock In', align: 'center', value: 'clockin', sortable: false },
        { text: 'Clock Out', align: 'center', value: 'clockout', sortable: false },
        { text: 'Hours', align: 'center', value: 'hours', sortable: false },
        { text: 'Actions', align: 'center', value: 'key', sortable: false }
      ],
      busyMessage: '',
      errorMessage: '',
      showConfirmDelete: false,
      itemDelete: {}
    }
  }
}
</script>

<style scoped>
</style>
