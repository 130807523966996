<template>
  <div id="main">
    <v-toolbar color="white" flat v-if="!inPrint">
      <v-toolbar-title>
        {{ heading }} Payroll Period
        <div class="subheading">{{ summary.start }}&nbsp;&nbsp;-&nbsp;&nbsp;{{ summary.end }}
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <span class="green--text headline font-weight-bold"
            v-if="summary.approved">
      <v-icon color="green" size="32px">mdi-check</v-icon>
        Approved
      </span>
      <v-spacer></v-spacer>

      <v-tooltip
          bottom
          v-if="!summary.approved && which!='current' && summary.id>0"
      >
        <template v-slot:activator="{ on }">
          <span v-on="on">
          <v-btn
              :disabled="isRed"
              @click="showConfirmApprove = true"
              class="white--text mx-4"
              color="primary"
          >
            <v-icon dark left>mdi-check-circle-outline</v-icon>
            Approve
          </v-btn>
          </span>
        </template>
        <span>Payroll cannot be approved if any items have errors (i.e. red numbers).</span>
      </v-tooltip>

      <v-btn @click="printPreview" class="white--text mx-4" color="blue-grey">
        <v-icon dark left>mdi-printer</v-icon>
        Print
      </v-btn>

    </v-toolbar>

    <v-card v-if="inPrint">

      <v-card-actions class="d-print-none">
        <v-spacer></v-spacer>
        <v-btn @click="cancelPrint()" color="primary" text>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn @click="print()" color="primary" text>Print</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-divider class="d-print-none"></v-divider>

      <v-card-title class="mx-12">
        <span class="display-1">{{ companyName }}</span>
        <v-spacer></v-spacer>
        <span class="subheading text-right">{{ fullName }}</span>
      </v-card-title>

      <v-container>
        <v-row>
          <v-col class="my-0 py-0 display-1 text-center">Payroll Report</v-col>
        </v-row>
        <v-row>
          <v-col class="my-0 py-1 headline text-center font-weight-medium">
            Period {{ summary.start }} thru {{ summary.end }}
          </v-col>
        </v-row>
        <v-row class="">
          <v-col class="my-0 py-0 subheading text-center font-weight-bold" v-if="summary.approved">
            Approved
          </v-col>
        </v-row>
      </v-container>

    </v-card>

    <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        dense
        disable-pagination
        hide-default-footer
    >

      <template v-slot:body="{ items }">
        <tbody>
        <tr :key="item.id" v-for="item in items">
          <td class="text-left font-weight-bold">
            {{ item.last }}, {{ item.first }}
          </td>
          <td
              :class="getHourColor(hour, summary.approved || inPrint)"
              :key="index"
              class="text-center"
              v-for="(hour, index) in item.hours"
          >
            {{ getHoursBlank(hour) }}
          </td>
          <td class="text-center font-weight-bold">
            {{ financialIB(item.total_hours) }}
          </td>
          <td class="text-center font-weight-bold">
            {{ financialI(item.pay_rate) }}
          </td>
          <td class="text-center font-weight-bold">
            {{ financialIB(item.total_pay) }}
          </td>
          <td class="justify-center layout"
              v-if="!inPrint && !summary.approved"
          >
            <v-icon @click="editItem(item)" class="mr-0" small
                    v-if="!summary.approved"
            >
              mdi-pencil
            </v-icon>
          </td>
        </tr>
        <tr v-if="items.length>0">
          <td><strong>TOTALS ({{ items.length }})</strong></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-center font-weight-bold">
            <strong>
              {{ financialI(summary.total_hours) }}
            </strong>
          </td>
          <td></td>
          <td class="text-center font-weight-bold">
            <strong>
              $ {{ financialI(summary.total_pay) }}
            </strong>
          </td>
          <td v-if="!summary.approved"></td>
        </tr>
        </tbody>
      </template>

    </v-data-table>

    <div v-if="!inPrint">
      <v-row class="pt-3 d-flex justify-space-around mb-6">
        <v-col>
          <v-btn @click="onRefreshClick" class="white--text mx-6" color="primary">
            <v-icon dark left>mdi-refresh</v-icon>
            Refresh
          </v-btn>
        </v-col>
        <v-col>
          <v-alert :value="(errorMessage!='')" outlined type="error">
            {{ errorMessage }}
          </v-alert>
        </v-col>
        <v-col>
          <div>
            <ClockAllOut class="pt-3"/>
          </div>
        </v-col>
      </v-row>

      <EditEmplHours></EditEmplHours>

      <v-dialog max-width="500" v-model="showConfirmApprove">
        <v-card>
          <v-card-title class="headline">Approve this payroll period?</v-card-title>

          <v-card-text>
            This finalizes this payroll for period {{ summary.start }} thru {{ summary.end }}.
            No further changes will be possible.<p/>
            An email will be sent to the bookkeeper
            indicating the payroll is now ready for processing.<p/>
            This action is irreversible.
          </v-card-text>

          <v-card-actions>
            <v-btn @click="showConfirmApprove = false" color="primary" text>Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="approve()" color="primary" text>Approve payroll</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <busy :message="busyMessage"></busy>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {parse, format, addDays} from 'date-fns'
import {eventBus} from '@/main'
import EditEmplHours from './EditHours'
import Busy from '../Busy'
import {getHourColor, getHoursBlank, financialI, financialIB} from '../utils'
import {
  EVT_FINISHED_EDIT_EMPLOYEE_HOURS,
  EVT_EDIT_EMPLOYEE_HOURS, EVT_FINISHED_CLOCK_ALL_OUT
} from '../../constants'
import {IN_PRINT} from '@/store/store_constants'
import ClockAllOut from "@/components/Admin/Payroll/ClockAllOut.vue";

export default {
  name: 'Payroll',
  components: {
    ClockAllOut,
    EditEmplHours,
    Busy
  },

  created() {
    console.log('Created PayrollTable, route:', this.$route.params.which)
    this.selectPeriod(this.$route.params.which)
    eventBus.$on(EVT_FINISHED_EDIT_EMPLOYEE_HOURS, () => {
      this.refresh()
    })
    eventBus.$on(EVT_FINISHED_CLOCK_ALL_OUT, () => {
      this.refresh()
    })
  },
  beforeDestroy() {
    this.busyMessage = ''
    this.errorMessage = ''
    console.log('BeforeDestroy:', this.$options.name)
  },

  computed: {
    // TODO need username, isCurrent, isApproved
    isRed() {
      if (this.which !== 'previous') {
        return false
      }
      return this.summary.error
    },
    inPrint() {
      console.log('Inprint:', this.$store.getters.inPrint)
      return this.$store.getters.inPrint
    },
    headers() {
      const startDate = parse(this.summary.start, 'yyyy-MM-dd', new Date());

      let hdrs = [];
      hdrs.push(
          {text: 'Name', align: 'left', value: 'last'},
      )
      for (let i = 0; i < this.summary.days; i++) {
        const day = format(addDays(startDate, i), "EEE") // i.e. Mon
        hdrs.push(
            {text: day, align: 'center'},
        )
      }
      hdrs.push(
          {text: 'Hours', align: 'center', value: 'total_hours'},
          {text: 'Rate $', align: 'center', value: 'pay_rate'},
          {text: 'Pay $', align: 'center', value: 'total_pay'},
      )

      // if in printing or payroll is approved, do not add the edit column
      if (!this.inPrint && !this.summary.approved) {
        hdrs.push({text: 'Edit', align: 'center', value: 'edit', sortable: false})
      }
      return hdrs
    },
    companyName() {
      return this.$store.getters.companyName
    },
    fullName() {
      return this.$store.getters.fullName
    }
  },

  watch: {
    '$route'(to) {
      this.selectPeriod(to.params.which)
    }
  },

  methods: {
    getHourColor,
    getHoursBlank,
    financialI,
    financialIB,

    selectPeriod(which) {
      console.log('Select:', which)
      this.which = which
      switch (this.which) {
        case 'current':
          this.payrollURL = '/payroll/current'
          this.heading = 'Current'
          break
        case 'previous':
          this.payrollURL = '/payroll/previous'
          this.heading = 'Previous'
          break
        default:
          this.payrollURL = '/payroll/' + which // hope this is a number
          this.heading = 'Archived'
          break
      }
      this.refresh()
    },
    onRefreshClick() {
      console.log('REFRESH CLICK')
      this.refresh()
    },
    refresh() {
      console.log('REFRESH payroll:', this.heading)
      if (!this.$store.getters.loggedIn) {
        return
      }
      this.busyMessage = 'Loading...'
      axios.get(this.payrollURL)
          .then(res => {
            this.summary = res.data
            console.log('Summary:', this.summary)
            this.items = this.summary.employees
            this.busyMessage = ''
          })
          .catch(error => {
            this.summary = {} // no summary
            this.items = [] // no employees
            console.log(error.toString())
            this.busyMessage = ''
            this.showAlert('Error unable to refresh payroll. ' + error.toString())
          })
    },
    approve() {
      console.log('Approve Payroll:', this.which)
      if (this.which === 'current' || this.summary.approved) {
        console.log("This payroll not eligible to approve.")
        this.showAlert('This payroll not eligible to approve.')
        return
      }
      this.showConfirmApprove = false
      this.busyMessage = 'Approving...'
      axios.put('/payroll/approve', this.summary.id)
          .then(res => {
            console.log('PUT', res)
            this.busyMessage = ''
            this.refresh()
          })
          .catch(error => {
            console.log(error.toString())
            this.busyMessage = ''
            this.showAlert('Error approving payroll. ' + error.toString())
          })
    },
    showAlert(msg) {
      this.errorMessage = msg
      setTimeout(() => (this.errorMessage = ''), 5000)
    },
    editItem(employee) {
      let rec = {
        'start': this.summary.start,
        'end': this.summary.end,
        'payrollURL': this.payrollURL,
        'employee': employee
      }
      console.log('EDIT ITEM:', rec)
      eventBus.$emit(EVT_EDIT_EMPLOYEE_HOURS, rec)
    },

    printPreview() {
      console.log('Print Preview Payroll')
      this.$store.commit(IN_PRINT, true)
    },
    print() {
      console.log('Print Payroll')
      window.print()
    },
    cancelPrint() {
      console.log('Cancel Print Payroll')
      this.$store.commit(IN_PRINT, false)
    }
  },
  data() {
    return {
      busyMessage: '',
      errorMessage: '',
      summary: {
        approved: false
      },
      items: [],
      which: '',
      heading: '',
      payrollURL: '',
      showConfirmApprove: false
    }
  }
}
</script>

<style scoped>
</style>
